/* name field validation*/
export function nameInput(val) {    //restricting input validation for name
    return val.replace(/[^a-zA-Z ]/g, '').substring(0, 50);
}

export function nameValid(val) {    //regex validation for name
    if (/^[a-zA-Z]+$/.test(val)) {
        return true
    } else {
        return false
    }
}


/* Email Field validation */
export function emailInput(val) {   //restricting input validation for email.
    val = val.toLowerCase();
    return val.replace(/[^a-zA-Z0-9\\@\\.\\_\\-]/g, '').substring(0, 150);
}

export function emailValid(val) {   //regex validation for email.
    if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/.test(val)) {
        return true
    } else {
        return false
    }
}

/* Check if a field is empty */
export function checkEmptyField(val) {
    return val === undefined || val === null || val === "";
}