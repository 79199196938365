import goCeresLogo from "../assets/images/goceres-logo.png";
import grantsLogo from "../assets/images/grants-logo.png";
import ittisaLogo from "../assets/images/ittisa-logo.png";
import adityaBirlaLogo from "../assets/images/aditya-birla-capital-logo.png";
import tataSteelLogo from "../assets/images/tata-steel-logo.png";
import kulkarniLawFirmLogo from "../assets/images/kulkarni-law-firm-logo.png";

const Portfolio = () => {
  return (
    <>
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={goCeresLogo} />
        </div>
        <div className="portfolio-content">
          <a href="https://goceres.com/" target="_blank">
            <h3>goCeres</h3>
          </a>
          <p>
            A document sharing web application built on MERN stack consisting of
            systems like authentication, notification, team, permission
            management and analytics.{" "}
          </p>
          <p>
            Tech Stack: React, Recoil, NodeJs, ExpressJS, MongoDB, Mongoose,
            AWS: EC2, CodeDeploy, S3, SES, Git and GitHub.
          </p>
        </div>
      </div>
      <hr />
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={grantsLogo} />
        </div>
        <div className="portfolio-content">
          <a href="https://grantspromotion.in/" target="_blank">
            <h3>Grant’s Microsite</h3>
          </a>
          <p>
            A web app developed specifically for mobile devices with interactive
            SpinWheel game and rewards. An interactive way to play, win and
            order drinks.
          </p>
          <p>Tech Stack: ReactJS, Firebase: Hosting and Cloudstore</p>
        </div>
      </div>
      <hr />
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={ittisaLogo} />
        </div>
        <div className="portfolio-content">
          <a href="https://ittisa.com/" target="_blank">
            <h3>Ittisa</h3>
          </a>
          <p>
            An official website of a digital marketing company built with the
            latest HTML5 and CSS3 with sleek animations that got integrated into
            the WordPress ecosystem.
          </p>
          <p>Tech Stack: HTML5, CSS3, BootStrap</p>
        </div>
      </div>
      <hr />
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={adityaBirlaLogo} />
        </div>
        <div className="portfolio-content">
          <a
            href="https://wealth.adityabirlacapital.com/digital-gold/"
            target="_blank"
          >
            <h3>Aditya Birla Wealth Management System</h3>
          </a>
          <p>
            A portal to book gold online and check prices in real time. Built
            with React, this web app helps visitors explore the offers offered
            by the company.
          </p>
          <p>Tech Stack: ReactJS (Only Frontend)</p>
        </div>
      </div>
      <hr />
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={tataSteelLogo} />
        </div>
        <div className="portfolio-content">
          <a href="https://www.tatatiscon.co.in/" target="_blank">
            <h3>Tata Tiscon</h3>
          </a>
          <p>
            A Tata Steel website built in association with a digital marketing
            agency. Worked on its frontend using the latest HTML and CSS.
          </p>
          <p>Tech Stack: HTML5 & CSS3</p>
        </div>
      </div>
      <hr />
      <div className="portfolio-item">
        <div className="portfolio-img">
          <img src={kulkarniLawFirmLogo} />
        </div>
        <div className="portfolio-content">
          <a href="https://kulkarnilawfirm.com/" target="_blank">
            <h3>Kulkarni Law Firm</h3>
          </a>
          <p>
            A law firm website based in the United States built using WordPress.
          </p>
          <p>Tech Stack: WordPress</p>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
