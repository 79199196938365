import maleAvatar from "../assets/images/male-avatar.png";
import femaleAvatar from "../assets/images/female-avatar.png";

const Testimonials = () => {
  return (
    <>
      <div className="testimonial-item">
        <div className="test-client-pic grey-bg">
          <img src={maleAvatar} />
        </div>
        <div className="testimonial-content">
          <p className="testimonial">
            Mukesh has worked on many programs and websites of my companies. He
            has been great and responsive.
          </p>
          <p className="test-client-info">
            Darshan Kulkarni,
            <br /> Regulatory and Compliance Attorney, Kulkarni Law Firm
          </p>
        </div>
      </div>
      <hr />

      <div className="testimonial-item">
        <div className="test-client-pic grey-bg">
          <img src={femaleAvatar} className="female-avatar" />
        </div>
        <div className="testimonial-content">
          <p className="testimonial">
            Mukesh coded designs for our various clients. Not only is he quick
            in responding but also explains why and how he did everything, which
            helps in handling clients. Will definitely use his service in
            future.
          </p>
          <p className="test-client-info">
            Swati Nathani,
            <br /> Co-Founder and CBO, Team Pumpkin
          </p>
        </div>
      </div>
      <hr />

      <div className="testimonial-item">
        <div className="test-client-pic grey-bg">
          <img src={maleAvatar} />
        </div>
        <div className="testimonial-content">
          <p className="testimonial">
            Mukesh had optimized my website very well. I will definitely
            approach him for my future projects.
          </p>
          <p className="test-client-info">
            Jay Sitlani,
            <br /> Founder and CEO, Crash Boom Bang Digital
          </p>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
