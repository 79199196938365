import kulkarniLawFirmLogo from "../assets/images/kulkarni-law-firm-logo.png";
import teamPumpkinLogo from "../assets/images/team-pumpkin-logo.png";
import piCommLogo from "../assets/images/pi-comm-logo.png";
import ittisaLogo from "../assets/images/ittisa-logo.png";
import grantsLogo from "../assets/images/grants-logo-black.png";
import tennisWorldLogo from "../assets/images/tennis-world-logo.png";

const Clients = () => {
  return (
    <>
      <ul className="client-list">
        <li>
          <img src={kulkarniLawFirmLogo} />
        </li>
        <li>
          <img src={teamPumpkinLogo} />
        </li>
        <li>
          <img src={piCommLogo} />
        </li>
        <li>
          <img src={ittisaLogo} />
        </li>
        <li>
          <img src={grantsLogo} />
        </li>
        <li>
          <img src={tennisWorldLogo} />
        </li>
      </ul>
    </>
  );
};

export default Clients;
