import frontenDoneLogo from "../assets/images/frontendone-logo.png";
import paytmInsiderLogo from "../assets/images/paytm-insider-logo.png";
import piCommLogo from "../assets/images/pi-comm-logo.png";
import voidImg from "../assets/images/void.png";
import teamPumpkinLogo from "../assets/images/team-pumpkin-logo.png";
import biteBankMediaLogo from "../assets/images/bite-bank-media.png";

const Experience = () => {
  return (
    <>
      <div className="exp-item">
        <div className="exp-img grey-bg">
          <img src={frontenDoneLogo} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Lead Front-end Developer at Frontendone</h3>
          <p className="exp-description">
            Coding, managing, assiting and collaborating with developers to
            achive the desired result. Serving as a lead front-end developer at
            Frontendone (A Frontend development company offering front-end
            development as a service).
          </p>
          <p className="exp-period">February 2021 - Current</p>
        </div>
      </div>
      <hr />

      <div className="exp-item">
        <div className="exp-img">
          <img src={paytmInsiderLogo} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Front-end developer at Paytm Insider</h3>
          <p className="exp-description">
            Worked on the company’s website which helps their users book online
            tickets for all types of events across cities in India. Upgraded
            codebase, fixed issues and implemented new features.
          </p>
          <p className="exp-period">November 2019 - January 2021</p>
        </div>
      </div>
      <hr />

      <div className="exp-item">
        <div className="exp-img grey-bg">
          <img src={piCommLogo} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Web Developer at pi Communications</h3>
          <p className="exp-description">
            Worked with pi communications, a direct marketing company to build
            websites for their clients. Worked on websites of top companies like
            Aditya Birla, TVS and Tata.
          </p>
          <p className="exp-period">October 2017 - October 2019</p>
        </div>
      </div>
      <hr />

      <div className="exp-item">
        <div className="exp-img grey-bg">
          <img src={voidImg} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Freelance Web Developer</h3>
          <p className="exp-description">
            Worked as a freelance web developer for a year. Helped many local
            clients with their website and provided technical solutions for
            their businesses.
          </p>
          <p className="exp-period">December 2016 - September 2017</p>
        </div>
      </div>
      <hr />

      <div className="exp-item">
        <div className="exp-img grey-bg">
          <img src={teamPumpkinLogo} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Web Developer at Team Pumpkin</h3>
          <p className="exp-description">
            Worked with Team Pumpkin, a digital marketing company and helped
            them build responsive HTML emails, websites and other applications.
          </p>
          <p className="exp-period">September 2015 - November 2016</p>
        </div>
      </div>
      <hr />

      <div className="exp-item">
        <div className="exp-img grey-bg">
          <img src={biteBankMediaLogo} />
        </div>
        <div className="exp-content">
          <h3 className="exp-title">Intern at Bite Bank Media</h3>
          <p className="exp-description">
            Worked as an Intern for an online dental service company. Created
            website templates for their CMS.
          </p>
          <p className="exp-period">January 2015 - March 2015</p>
        </div>
      </div>
    </>
  );
};

export default Experience;
