import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import * as utils from "./utils/utils.js";
import Navigation from "./Components/Navigation.js";
// import "./assets/styles/reset.css";
import "./App.css";
import "./index.css";
// import "./assets/styles/style.css";
import $ from "jquery";
import "jquery-ui/ui/widgets/tabs";
import Portfolio from "./Components/Portfolio.js";
import Experience from "./Components/Experience.js";
import Testimonials from "./Components/Testimonials.js";
import Clients from "./Components/Clients.js";
import Skills from "./Components/Skills.js";
import Technologies from "./Components/Technologies.js";
import Certifications from "./Components/Certifications.js";
import About from "./Components/About.js";
import Contact from "./Components/Contact.js";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $("#tabs").tabs();
  }

  render() {
    return (
      <div className="">
        <section className="main-container">
          <div className="tab-nav" id="tabs">
            <ul>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="#experience">Experience</a>
              </li>
              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="#clients">Clients</a>
              </li>
              <li>
                <a href="#certifications">Certifications</a>
              </li>
              <li>
                <a href="#skills">Skills</a>
              </li>
              <li>
                <a href="#technologies">Technologies</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <div id="about">
              <About />
            </div>
            <div id="portfolio">
              <Portfolio />
            </div>
            <div id="experience">
              <Experience />
            </div>
            <div id="testimonials">
              <Testimonials />
            </div>
            <div id="clients">
              <Clients />
            </div>
            <div id="certifications">
              <Certifications />
            </div>
            <div id="skills">
              <Skills />
            </div>
            <div id="technologies">
              <Technologies />
            </div>
            <div id="contact">
              <Contact />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
