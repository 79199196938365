import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import * as utils from "../utils/utils.js";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);

  function checkErrors() {
    let isError = false;

    if (utils.checkEmptyField(name)) {
      //name validation
      setNameError("Please enter your name");
      isError = true;
    } else if (!utils.nameValid()) {
      setNameError("Invalid name");
      isError = true;
    }

    if (utils.checkEmptyField(email)) {
      //email validation
      setEmailError("Please enter your email");
      isError = true;
    } else if (!utils.emailValid(email)) {
      setEmailError("Invalid email");
      isError = true;
    }

    return isError;
  }

  async function sendMessage() {
    if (!checkErrors()) {
      let result = await firebase
        .firestore()
        .collection("emails")
        .add({
          to: "mbmaurya.maurya@gmail.com",
          replyTo: `${email}`,
          message: {
            subject: `Message from personal website: ${name}`,
            html: `${name}
                   has sent you a message:<br/> 
                   ${message}
                `,
          },
        })
        .then((docRef) => {
          alert("Message sent successfully!");
        })
        .catch((error) => {
          alert("An unknown error occured, Please try again");
        });
      resetForm();
    }
  }

  function resetForm() {
    setName("");
    setEmail("");
    setMessage("");
    setEnableSubmit(false);
  }

  function clearErrorMessage() {
    //clear Error Messages
    if (!utils.checkEmptyField(nameError)) {
      setNameError("");
    }

    if (!utils.checkEmptyField(emailError)) {
      setEmailError("");
    }
  }

  function reCaptchaUpdate(value) {
    console.log("Captcha value:", value);
    setEnableSubmit(true);
  }

  return (
    <>
      <form id="form" method="POST" className="contact-form">
        {/* <span
          className="close-form"
          id="closeForm"
          onClick={() => clearErrorMessage()}
        >
          X
        </span> */}
        <div>
          <label>
            Name <span className="text-error">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(evt) => {
              setName(utils.nameInput(evt.target.value));
              if (nameError !== "") {
                setNameError("");
              }
            }}
            required
          />
          <div className="text-error">{nameError}</div>
        </div>
        <div>
          <label>
            Email <span className="text-error">*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(evt) => {
              setEmail(utils.emailInput(evt.target.value));

              if (emailError !== "") {
                setEmailError("");
              }
            }}
            required
          />
          <div className="text-error">{emailError}</div>
        </div>
        <div>
          <label>Message</label>
          <textarea
            id="message"
            onChange={(evt) => setMessage(evt.target.value)}
          ></textarea>
        </div>
        <ReCAPTCHA
          sitekey="6LdsRnUpAAAAADqn9Sw2OeqVIBOkVPAvm_a71Al1"
          onChange={reCaptchaUpdate}
        />
        <input
          type="button"
          value="Submit"
          name="submit"
          disabled={!enableSubmit}
          onClick={() => sendMessage()}
        />
      </form>
    </>
  );
};

export default Contact;
