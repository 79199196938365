import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import * as utils from "../utils/utils.js";
import profilePic from "../assets/images/mukesh-maurya.jpg";
import ReCAPTCHA from "react-google-recaptcha";

const About = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);

  // functions
  function showForm() {
    document.getElementById("form").style.display = "block";
  }

  function hideForm() {
    document.getElementById("form").style.display = "none";
    setEnableSubmit(false);
  }

  function checkErrors() {
    let isError = false;

    if (utils.checkEmptyField(name)) {
      //name validation
      setNameError("Please enter your name");
      isError = true;
    } else if (!utils.nameValid()) {
      setNameError("Invalid name");
      isError = true;
    }

    if (utils.checkEmptyField(email)) {
      //email validation
      setEmailError("Please enter your email");
      isError = true;
    } else if (!utils.emailValid(email)) {
      setEmailError("Invalid email");
      isError = true;
    }

    return isError;
  }

  function sendMessage() {
    if (!checkErrors()) {
      firebase
        .firestore()
        .collection("emails")
        .add({
          to: "mbmaurya.maurya@gmail.com",
          replyTo: `${email}`,
          message: {
            subject: `Message from personal website: ${name}`,
            html: `${name}
                   has sent you a message:<br/> 
                   ${message}
                `,
          },
        })
        .then((docRef) => {
          alert("Message sent successfully!");
        })
        .catch((error) => {
          alert("An unknown error occured, Please try again");
        });

      hideForm();
      // alert("message sent.");
    }
  }

  function clearErrorMessage() {
    //clear Error Messages
    hideForm();
    if (!utils.checkEmptyField(nameError)) {
      setNameError("");
    }

    if (!utils.checkEmptyField(emailError)) {
      setEmailError("");
    }
  }

  // side effects
  useEffect(() => {
    var firebaseConfig = {
      apiKey: "AIzaSyAIxH-LoPDuTD8sCGLdW-AyM_E_LCvsnvw",
      authDomain: "personal-website-68ab3.firebaseapp.com",
      projectId: "personal-website-68ab3",
      storageBucket: "personal-website-68ab3.appspot.com",
      messagingSenderId: "650686357698",
      appId: "1:650686357698:web:cb072605c76e8183909b5b",
      measurementId: "G-3SCMGHTPZP",
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }
  }, []);

  function reCaptchaUpdate(value) {
    setEnableSubmit(true);
  }

  return (
    <>
      <img src={profilePic} alt="Mukesh Maurya" className="profile-picture" />
      <h2>Hello,</h2>
      <p>
        Thank you for visiting my website. Allow me to briefly introduce myself.
        I am an IT graduate and a full-stack web developer with over eight years
        of experience in the tech industry.
      </p>
      <p>
        I have worked with several companies as both an employee and consultant,
        including Paytm, one of the most influential companies in India and
        currently working as a lead front-end developer at{" "}
        <a href="https://frontendone.com" target="_blank">
          Frontendone
        </a>
        .
      </p>
      <p>
        Aside from coding, I am a fitness enthusiast and aquarium hobbyist. If
        you need any assistance or guidance regarding websites or tech, you can
        reach out to me{" "}
        <a href="#" id="showForm" onClick={() => showForm()}>
          here
        </a>
        .
      </p>
      <p>Looking forward to hearing from you soon!</p>

      <form id="form" method="POST">
        <span
          className="close-form"
          id="closeForm"
          onClick={() => clearErrorMessage()}
        >
          X
        </span>
        <div>
          <label>
            Name <span className="text-error">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(evt) => {
              setName(utils.nameInput(evt.target.value));
              if (nameError !== "") {
                setNameError("");
              }
            }}
            required
          />
          <div className="text-error">{nameError}</div>
        </div>
        <div>
          <label>
            Email <span className="text-error">*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(evt) => {
              setEmail(utils.emailInput(evt.target.value));

              if (emailError !== "") {
                setEmailError("");
              }
            }}
            required
          />
          <div className="text-error">{emailError}</div>
        </div>
        <div>
          <label>Message</label>
          <textarea
            id="message"
            onChange={(evt) => setMessage(evt.target.value)}
          ></textarea>
        </div>
        <ReCAPTCHA
          sitekey="6LdsRnUpAAAAADqn9Sw2OeqVIBOkVPAvm_a71Al1"
          onChange={reCaptchaUpdate}
        />
        <input
          type="button"
          value="Submit"
          name="submit"
          disabled={!enableSubmit}
          onClick={() => sendMessage()}
        />
      </form>
    </>
  );
};

export default About;
