import timeManagementIcon from "../assets/images/skills/time-management.png";
import communicationIcon from "../assets/images/skills/communication.png";
import leaderShipIcon from "../assets/images/skills/leadership.png";
import problemSolvingIcon from "../assets/images/skills/problem-solving.png";
import projectManagementIcon from "../assets/images/skills/project-management.png";
import teamWorkIcon from "../assets/images/skills/teamwork.png";
import attentionsToDetailIcon from "../assets/images/skills/attention-to-detail.png";

const Skills = () => {
  return (
    <>
      <ul className="skills-list">
        <li>
          <img src={projectManagementIcon} />
          <h4>Project Management</h4>
        </li>
        <li>
          <img src={communicationIcon} />
          <h4>Communication</h4>
        </li>
        <li>
          <img src={teamWorkIcon} />
          <h4>Team Work</h4>
        </li>
        <li>
          <img src={problemSolvingIcon} />
          <h4>Problem Solving</h4>
        </li>
        <li>
          <img src={leaderShipIcon} />
          <h4>Leadership</h4>
        </li>
        <li>
          <img src={timeManagementIcon} />
          <h4>Time Management</h4>
        </li>
        <li>
          <img src={attentionsToDetailIcon} />
          <h4>Attention to Detail</h4>
        </li>
      </ul>
    </>
  );
};

export default Skills;
