import React from "react";
import Slider from "react-slick";

import awsCertificate from "../assets/images/certificates/aws-cloud-essentials.jpg";
import dsaCertificate from "../assets/images/certificates/dsa-and-algorithms.jpg";
import gitCertificate from "../assets/images/certificates/git-and-github.jpg";
import npmCertificate from "../assets/images/certificates/npm.png";
import projectManagementCertificate from "../assets/images/certificates/project-management-foundations.jpg";
import reactFullStackCertificate from "../assets/images/certificates/react-creating-and-hosting-a-full-stack-site.png";

const Certifications = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {/* <div className="certificates"> */}
      <Slider {...settings}>
        <div className="certificate-container">
          <img src={reactFullStackCertificate} />
        </div>
        <div className="certificate-container">
          <img src={awsCertificate} />
        </div>
        <div className="certificate-container">
          <img src={gitCertificate} />
        </div>
        <div className="certificate-container">
          <img src={dsaCertificate} />
        </div>
        <div className="certificate-container">
          <img src={projectManagementCertificate} />
        </div>
        <div className="certificate-container">
          <img src={npmCertificate} />
        </div>
      </Slider>
      {/* </div> */}
    </>
  );
};

export default Certifications;
