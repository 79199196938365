import javascriptLogo from "../assets/images/technologies/javascript.png";
import reactLogo from "../assets/images/technologies/react.png";
import reduxLogo from "../assets/images/technologies/redux.png";
import recoil from "../assets/images/technologies/recoil.png";
import jQeuryLogo from "../assets/images/technologies/jquery.png";
import html5Logo from "../assets/images/technologies/html5.png";
import css3Logo from "../assets/images/technologies/css3.png";
import bootstrapLogo from "../assets/images/technologies/bootstrap.png";
import scssLogo from "../assets/images/technologies/scss.png";
import nodeLogo from "../assets/images/technologies/nodejs.png";
import expressLogo from "../assets/images/technologies/expressjs.png";
import mongoDBLogo from "../assets/images/technologies/mongodb.png";
import mySqlLogo from "../assets/images/technologies/mysql.png";
import awsLogo from "../assets/images/technologies/aws.png";
import firebaseLogo from "../assets/images/technologies/firebase.png";
import gitLogo from "../assets/images/technologies/git.png";
import gitHubLogo from "../assets/images/technologies/github.png";
import phpLogo from "../assets/images/technologies/php.png";
import wordPressLogo from "../assets/images/technologies/wordpress.png";
import figmaLogo from "../assets/images/technologies/figma.png";
import photoShopLogo from "../assets/images/technologies/photoshop.png";

const Technologies = () => {
  return (
    <>
      <ul className="technology-list">
        <li>
          <img src={javascriptLogo} />
          <h4>JavaScript</h4>
        </li>
        <li>
          <img src={reactLogo} />
          <h4>ReactJS</h4>
        </li>
        <li>
          <img src={reduxLogo} />
          <h4>Redux</h4>
        </li>
        <li>
          <img src={recoil} />
          <h4>Recoil</h4>
        </li>
        <li>
          <img src={jQeuryLogo} />
          <h4>JQuery</h4>
        </li>
        <li>
          <img src={html5Logo} />
          <h4>HTML5</h4>
        </li>
        <li>
          <img src={css3Logo} />
          <h4>CSS3</h4>
        </li>
        <li>
          <img src={bootstrapLogo} />
          <h4>BootStrap</h4>
        </li>
        <li>
          <img src={nodeLogo} />
          <h4>NodeJS</h4>
        </li>
        <li>
          <img src={expressLogo} />
          <h4>ExpressJS</h4>
        </li>
        <li>
          <img src={mongoDBLogo} />
          <h4>MongoDB</h4>
        </li>
        <li>
          <img src={mySqlLogo} />
          <h4>MySQL</h4>
        </li>
        <li>
          <img src={scssLogo} />
          <h4>SCSS</h4>
        </li>
        <li>
          <img src={awsLogo} />
          <h4>AWS</h4>
        </li>
        <li>
          <img src={firebaseLogo} />
          <h4>Firebase</h4>
        </li>
        <li>
          <img src={gitLogo} />
          <h4>GIT</h4>
        </li>
        <li>
          <img src={gitHubLogo} />
          <h4>GitHub</h4>
        </li>
        <li>
          <img src={phpLogo} />
          <h4>PHP</h4>
        </li>
        <li>
          <img src={wordPressLogo} />
          <h4>WordPress</h4>
        </li>
        <li>
          <img src={photoShopLogo} />
          <h4>Photoshop</h4>
        </li>
        <li>
          <img src={figmaLogo} />
          <h4>Figma</h4>
        </li>
      </ul>
    </>
  );
};

export default Technologies;
